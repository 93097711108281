import React from 'react'
import Helmet from 'react-helmet'

// Views
import AtomSeo from '../../Atoms/Seo';
import Navbar from '../Navbar'
import Footer from '../Footer'
 import '../../all.sass'

const TemplateWrapper = ({ children, isHomePage = false, title, seo }) => {

  return (
    <div id="wrapper" className={isHomePage ? "homepage-wrap" : ""}>
      {/* <Helmet title={title + (isHomePage ? '' : ' | Sakui')} /> */}
      <AtomSeo seo={seo} />
      <Navbar
        activePageTitle={title}
        isHomePage={isHomePage}
      />
      <div className="main-content">{children}</div>
      {
        !isHomePage &&
        <Footer />
      }
    </div>
  );
}

export default TemplateWrapper
