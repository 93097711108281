// Basics
import React from 'react';
import { Link } from 'gatsby';

const RenderMainFooter = (
  <>
    <div className="footer-left">Sakui &copy;2019</div>
    <div className="footer-right">
      <ul>
        {/* <li>
          <Link to="/">
            Cookie policy
          </Link>
        </li>
        <li>
          <Link to="/">
            Privacy notice
          </Link>
        </li> */}
        <li>
          <div className="social">
            <Link to="/" className="twitter">
              <i className="fa fa-instagram" />
            </Link>
            <Link to="/" className="twitter">
              <i className="fa fa-twitter" />
            </Link>
            <Link to="/" className="twitter">
              <i className="fa fa-facebook" />
            </Link>
            <Link to="/" className="twitter">
              <i className="fa fa-linkedin" />
            </Link>
          </div>
        </li>
      </ul>
    </div>
  </>
);

function Footer ({
  isFullSection = false
}) {
  
  // If it doesn't inside full section
  if (!isFullSection) {
    return (
      <div id="footer">
        <div className="container-fluid">
          {RenderMainFooter}
        </div>
      
      </div>
    );
  }

  return (
    <div className="section fp-auto-height" id="footer">
      <div className="container-fluid">
        {RenderMainFooter}
      </div>
     </div>
  );
}

export default Footer
