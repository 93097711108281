// Basics
import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';

// Utilites
import { includes } from 'lodash';

// Assets
import logo from '../../../img/logo.png';

class Navbar extends React.Component {

  constructor(props) {
    super(props);

    // Binding
    this.handleToggleMainNav = this.handleToggleMainNav.bind(this);
    this.handleCloseMainNav = this.handleCloseMainNav.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleToggleMainNav() {
    if (includes(document.body.classList, 'menu-open')) {
      document.body.classList.remove('menu-open');
    } else {
      document.body.classList.add('menu-open');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleCloseMainNav() {
    if (includes(document.body.classList, 'menu-open')) {
      document.body.classList.remove('menu-open');
    }
  }

  render() {
    const {
      activePageTitle,
      isHomePage
    } = this.props;
    const thisObject = this;

    return (
      <StaticQuery
        query={graphql`
        query {
          allWordpressWpApiMenusMenusItems (filter: {wordpress_id: {eq: 3}}) {
            edges {
              node {
                name
                items {
                  object_id
                  object_slug
                  title
                  url
                  type
                }
              }
            }
          }
        }
      `}
        render={data => (
          <div id="header">
            <div className="container-fluid">
              <div id="logo">
                <Link to="/" onClick={this.handleCloseMainNav}>
                  <img src={logo} alt="Agency" />
                </Link>
              </div>
              <div className="header-right">
                {!isHomePage && (
                  <span className="active-page-title">
                    {activePageTitle}
                  </span>
                )}


                <div
                  className="hamburger"
                  onClick={this.handleToggleMainNav}
                >
                  <span />
                  <span />
                  <span />
                </div>
              </div>
              <div className="full-menu">
                <div className="menu-list">
                  <ul>
                    {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item) => {
                      return (
                        <li key={item.object_id}>
                          <Link
                            className="navbar-item"
                            to={`/${item.object_slug}`}
                            key={item.object_slug}
                            onClick={thisObject.handleCloseMainNav}
                          >
                            {item.title}
                          </Link>
                        </li>
                      )
                    })}

                  </ul>

                </div>
              </div>
            </div>
           </div>
        )}
      />
    )
  }
}

Navbar.propTypes = {
  activePageTitle: PropTypes.string.isRequired,
  isHomePage: PropTypes.bool.isRequired
};

export default Navbar;
